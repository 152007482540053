import React from 'react';

function Logo() {
  return (
    <img
      loading='lazy'
      src='https://cdn.builder.io/api/v1/image/assets/TEMP/1d6ba056522119084999b6a8130a44b812a2f5fac74370aaffefa47c5855aab1?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
      alt='Company Logo'
      className='object-contain shrink-0 self-stretch my-auto aspect-[4.08] w-[110px] cursor-pointer'
      onClick={() => {
        window.location.href = window.location.origin;
      }}
    />
  );
}

function Navigation() {
  const navItems = [
    { label: 'Offers', link: '#' },
    { label: 'Plans & Pricing', link: '#' },
    {
      label: 'Our Services',
      link: '#',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/8b0b329ecf35d083940522a9c59db99f1970959d5c8ce5e4d7ca0fccbc5ef58a?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    },
  ];

  return (
    <nav className='flex overflow-hidden flex-wrap gap-8 justify-center items-center self-stretch my-auto min-w-[240px] text-slate-900 max-md:max-w-full max-sm:hidden'>
      <button className='flex gap-1.5 justify-center items-center self-stretch px-4 py-2.5 my-auto tracking-tight text-white bg-slate-900 rounded-[41px]'>
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/99b4371cb89256b1a0fc20be84866624bed814a56191e2fad5406dbeef29b483?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt=''
          className='object-contain shrink-0 self-stretch my-auto w-5 aspect-square'
        />
        <span>Become a Aluma Partner</span>
      </button>
      {navItems.map((item, index) => (
        <a
          key={index}
          href={item.link}
          className='self-stretch my-auto flex gap-1 justify-center items-center'
        >
          <span>{item.label}</span>
          {item.icon && (
            <img
              loading='lazy'
              src={item.icon}
              alt=''
              className='object-contain shrink-0 self-stretch my-auto w-6 aspect-square'
            />
          )}
        </a>
      ))}
    </nav>
  );
}

function ActionButtons() {
  return (
    <div className='flex gap-6 justify-center items-center self-stretch my-auto min-w-[240px] max-sm:hidden'>
      <a href='#contact' className='self-stretch my-auto text-slate-900'>
        Contact Us
      </a>
      <a
        href='#signup'
        className='gap-2 self-stretch px-4 py-2 my-auto text-green-500 rounded-md border-2 border-green-500 border-solid'
      >
        Sign Up for Free
      </a>
    </div>
  );
}

function HeaderPage() {
  return (
    <header className='flex flex-wrap gap-10 items-center self-stretch py-6 pr-6 pl-16 w-full text-base font-medium bg-white shadow-[0px_16px_20px_rgba(0,0,0,0.05)] max-md:px-5 max-md:max-w-full'>
      <Logo />
      <Navigation />
      <ActionButtons />
    </header>
  );
}

export default HeaderPage;
