import HomePage from './views/HomePage';
import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter,
} from 'react-router-dom';
import './App.css';
import PrivacyPage from './views/privacy';
import TermsAndCondPage from './views/termsandcond';
import OffersPage from './views/OffersPage';
const router = createHashRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPage />,
  },
  {
    path: '/termsandcondition',
    element: <TermsAndCondPage />,
  },
  {
    path: '/offers',
    element: <OffersPage />,
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
